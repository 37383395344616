// Colors
$color-ci-blue: #142847;
$color-ci-blue-gradient-vertical: linear-gradient(180deg, rgba(28, 37, 51, 1) 0%, rgba(20, 40, 71, 1) 100%); // top / #142847 - bottom / #1c2533;
$color-ci-blue-gradient-diagonal: linear-gradient(135deg, rgba(28, 37, 51, 1) 0%, rgba(20, 40, 71, 1) 100%); // top / #142847 - bottom / #1c2533;
$color-ci-pink: #fc45f0;
$color-ci-pink-hover: #ffaaf9;
$color-ci-purple: #a500d9;
$color-ci-purple-gradient-vertical: linear-gradient(180deg, rgba(190, 0, 196, 1) 0%, rgba(165, 0, 217, 1) 100%); // top / #be00c4 - bottom / #a500d9;
$color-ci-purple-gradient-diagonal: linear-gradient(135deg, rgba(190, 0, 196, 1) 0%, rgba(165, 0, 217, 1) 100%); // top-left / #be00c4 - bottom-right / #a500d9;
$color-ci-turquoise: #009CB9;
$color-ci-turquoise-gradient-vertical: linear-gradient(180deg, rgba(34, 166, 214, 1) 0%, rgba(0, 156, 185, 1) 100%); // top / #22a6d6 - bottom / #009cb9;
$color-ci-turquoise-gradient-diagonal: linear-gradient(135deg, rgba(34, 166, 214, 1) 0%, rgba(0, 156, 185, 1) 100%); // top / #22a6d6 - bottom / #009cb9;

$color-background-darkblue: #202233;
$color-background-grey: #f2f3f4;

$color-border-blue: rgba(78, 116, 175, 0.5); // #4e74af
$color-border-purple: rgba(136, 0, 196, 0.5); // #8800c4
$color-border-grey: rgba(220, 225, 230, 0.5); // #dce1e6

$color-font-primary: $color-ci-blue;

// Box Shadows
$box-shadow-area-primary: 0 0 8px 8px rgba(15, 64, 100, 0.2); // #0f4064
$box-shadow-area-secondary: 0 0 14px 0 rgba(15, 64, 100, 0.1);// #0f4064
$box-shadow-area-tertirary: 4px 4px 10px rgba(15, 64, 100, 0.2); // #0f4064
$box-shadow-button: 0 0 14px 0 rgba(15, 64, 100, 0.4); // #0f4064
$box-shadow-drag-preview: 10px 10px 4px 0 rgba(15, 64, 100, 0.2);
$box-shadow-drag-preview-slot: inset 3px 3px 6px 0 rgba(4, 15, 33, 0.6);
$box-shadow-tooltip: 10px 10px 16px 0 rgba(15, 64, 100, 0.2);

// Durations
$duration-hover: 0.25s;
$duration-fade: 0.5s;
$duration-slide: 0.5s;

/*$color-background-grey: #f2f4f5;
$color-background-scroll-bar: #202233;
$color-background-switch-rail: #071429;

$color-border-black: rgba(38, 41, 63, 0.4);
$color-border-grey: #f2f4f5;
$color-border-blue: rgba(78, 116, 175, 0.5);
$color-border-drag-preview-slot: #20375a;

$color-font-primary: $color-ci-blue;*/

// Box Shadows
/*$box-shadow-primary: 4px 4px 10px 0 rgba(15, 64, 100, 0.15);
$box-shadow-secondary: 6px 10px 12px 0 rgba(44, 91, 154, 0.15);
$box-shadow-header: 0 7px 23px 0 rgba(15, 64, 100, 0.3);
$box-shadow-drag-preview: 10px 10px 4px 0 rgba(15, 64, 100, 0.2);*/
//$box-shadow-drag-preview-background: rgba(15, 64, 100, 0.2);
//$box-shadow-drag-preview-slot: inset 3px 3px 6px 0 rgba(4, 15, 33, 0.6);
