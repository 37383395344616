* {
  box-sizing: border-box;
  outline: none !important;
  word-break: break-word;
}
html, body {
  @include font-regular(14px);
  color: $color-font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#body {
  min-height: 100vh;
}
#root {
  min-height: 100vh;
  overflow: hidden;
  background: $color-background-grey;
}
